<script lang="ts" setup>
import {
	OBannersRefferal,
	OBannersQuest,
	OBannersWeekGame,
	OBannersPresetPackage,
	OBannersBox,
	OBannersFlipTheCoin,
	OChallengeSeasonBanner,
	OBannersSeason,
	OBannersScratchCards,
	OBannersDepositStreak,
	OBannersTournament
} from "#components";

import type { KeenSliderOptions, KeenSliderInstance } from "keen-slider";

import type { HomeBanner } from "@/types";

defineProps<{
	isLoading?: boolean;
}>();

const bannersList: Record<string, unknown> = {
	bannerPresetPackage: OBannersPresetPackage,
	weekGame: OBannersWeekGame,
	referral: OBannersRefferal,
	quest: OBannersQuest,
	magicBox: OBannersBox,
	flipTheCoin: OBannersFlipTheCoin,
	challenge: OChallengeSeasonBanner,
	season: OBannersSeason,
	scratchBanner: OBannersScratchCards,
	depositStreakBanner: OBannersDepositStreak,
	bannerTournament: OBannersTournament
} as const;

const { filteredBanners, questData } = useHomePage();

const interval = ref();
const sliderActiveSlide = ref(0);
const opacities = ref<number[]>([]);

const autoplay = (slider: KeenSliderInstance) => {
	clearInterval(interval.value);
	interval.value = setInterval(() => {
		if (slider.track.details) {
			slider.track.details.maxIdx === slider.track.details.abs ? slider.moveToIdx(0) : slider.next();
		}
	}, 5000);
};
const sliderRef = ref();

const sliderOptions: KeenSliderOptions = {
	loop: true,
	selector: ".fader__slide",
	defaultAnimation: {
		duration: 1000
	},
	created(slider: KeenSliderInstance) {
		autoplay(slider);
	},
	slideChanged(slider: KeenSliderInstance) {
		autoplay(slider);
		sliderActiveSlide.value = slider.track.details.rel;
	},
	detailsChanged: (slider: KeenSliderInstance) => {
		opacities.value = slider?.track?.details?.slides.map((slide) => slide.portion) || [1, 0, 0, 0];
	}
};

onMounted(() => {
	watch(
		() => filteredBanners.value?.length,
		() => {
			nextTick(() => {
				sliderRef.value?.slider?.update();
			});
		}
	);
});
</script>
<template>
	<div class="slider__wrapper" :class="{ 'slider__wrapper--dots': filteredBanners.length > 1 }">
		<LazyASlider ref="sliderRef" fade-mode :options="sliderOptions">
			<template #default>
				<div
					v-for="(banner, index) in filteredBanners"
					:key="index"
					:class="['fader__slide', { single: filteredBanners.length < 2, view: sliderActiveSlide === index }]"
					:style="{ opacity: opacities[index] }"
				>
					<component
						:is="bannersList[banner?.type]"
						v-if="banner?.type in bannersList"
						:banner="banner"
						:class="{ 'event-none': opacities[index] === 0 }"
						:questData="questData?.quest"
					/>
					<OBannersHero v-else :banner="banner as HomeBanner" />
				</div>
			</template>
			<template #dots="{ slider }">
				<div v-if="filteredBanners.length > 1" class="pagination">
					<div
						v-for="(_slide, idx) in filteredBanners.length"
						:key="idx"
						:class="{ dot: true, 'dot-active': sliderActiveSlide === idx }"
						@click="slider?.moveToIdx(idx)"
					/>
				</div>
			</template>
		</LazyASlider>
	</div>
</template>
<style scoped lang="scss">
.event-none {
	pointer-events: none;
}
.slider__wrapper {
	height: 430px;
	width: 100%;

	@include media-breakpoint-up(md) {
		height: 300px;
	}

	&:deep(.fader),
	&:deep(.app-banner) {
		height: 100%;
	}

	&:deep(.fader) {
		overflow: visible;
	}

	&--dots {
		margin-bottom: 26px;
	}
}

.keen-slider__slide {
	width: 100%;
}

.fader {
	overflow: visible;
}

.fader__slide {
	flex-shrink: 0;
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	transform: none !important;
	display: none;

	&.single {
		opacity: 1 !important;
	}

	&.view {
		display: block;
	}
}

.pagination {
	display: flex;
	gap: 16px;
	justify-content: center;
	margin-top: 16px;
	.dot {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: var(--gray-700);
		cursor: pointer;
		&-active {
			background: var(--secondary-400);
		}
	}
}
</style>
